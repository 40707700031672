<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-input v-model="queryInfo.inscription" class="input" placeholder="碑文/后缀" clearable></el-input>
      <el-button type="primary" @click="search" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>

    <div style="color: orange;padding-bottom:10px;font-size: 12px">
      说明:Intel类型是5位碑文，AMD的是SN码下划线后半部分(如："9JP4064S10526_YD3200C5M4MFH"取下划线后内容"YD3200C5M4MFH")。
    </div>
    <el-table :data="cpuList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="cpuType" label="CPU类型"></el-table-column>
      <el-table-column align="center" prop="inscription" label="碑文/后缀"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加碑文" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="CPU类型：">
          <el-select v-model="formData.cpuType" placeholder="请选择CPU类型" filterable style="width: 350px">
            <el-option value="intel" label="Intel"></el-option>
            <el-option value="amd" label="AMD"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="碑文/后缀：">
          <el-input v-model.trim="formData.inscription" placeholder="请输入碑文/后缀" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "CpuInscriptionMaterial",
  data() {
    return {
      queryInfo: {
        materialCode: '',
        inscription: ''
      },
      formData: {
        materialCode: '',
        cpuType: 'intel',
        inscription: ''
      },
      cpuList: [],
      dialogVisible: false,
      loading: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('cpu/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.cpuList = res.data || []
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('cpu/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.formData.inscription) {
        return this.$message.error('碑文不能为空');
      }
      if (this.formData.cpuType == 'intel' && this.formData.inscription.length != 5) {
        return this.$message.error('Intel类型CPU碑文必须为5位');
      }
      if (this.formData.cpuType == 'amd' && this.formData.inscription.length < 6) {
        return this.$message.error('AMD类型CPU后缀必须大于5位');
      }
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }

      const {data: res} = await this.$axios.post('cpu/add', this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.search();
      this.handleClose()
      this.formData.materialCode = ''
      this.formData.cpuType = 'intel'
      this.formData.inscription = ''
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>